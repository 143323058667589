body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


.indicator {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border: 1px #666 solid;
  margin: 0;
}

.indicator.active {
  color: #fff;
  background: #666;
}



  @media screen and (max-width: 480px) {
    .react-slideshow-container + div.indicators{
        display: none;
    }
  }

  .react-slideshow-container + ul.indicators {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    position: absolute !important;
    right: 5% !important;
    margin-top: -50px !important;
    z-index: 999;
  }

/* .react-slideshow-container + div.indicators {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    position: absolute !important;
    right: 150px !important;
    margin-top: -80px !important;
    z-index: 999;
  } */

  
  .react-slideshow-container + ul.indicators li {
    display: inline-block;
    position: relative;
    width: 20px !important;
    height: 20px !important;
    padding: 15px !important;
    margin: 0 !important;
  }
  
  .react-slideshow-container + ul.indicators .each-slideshow-indicator {
    border: none;
    opacity: 1;
    cursor: pointer;
  }
  
  .react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
    content: '';
    background: none !important;
    border: 1px solid rgb(255, 255, 255) !important;
    background-color: rgb(255, 255, 255) !important;
    text-align: center;
  }
  
  .react-slideshow-container + ul.indicators .each-slideshow-indicator:hover,
  .react-slideshow-container + ul.indicators .each-slideshow-indicator.active,
  .react-slideshow-container + ul.indicators .each-slideshow-indicator:focus {
    opacity: 0.8 !important;
    outline: none;
  }


/* FullpageLoader */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #12B8B3;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


